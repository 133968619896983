import { BadgeValue } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { DynamicPackageResult } from '@AuroraTypes';
import { useFormattedPassengerPrice } from '@Components/Price/useFormattedPassengerPrice';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';

export type ValueOffSashProps = {
  pricing: Pick<DynamicPackageResult['pricing'], 'discount' | 'discountPresentation'>;
};

export const ValueOffSash: React.FC<ValueOffSashProps> = ({ pricing }) => {
  const { t } = useTranslation();

  const price = pricing.discount;
  const formattedPassengerPrice = useFormattedPassengerPrice({
    value: price!,
    suffixType: 'short',
  });

  return (
    <BadgeValue
      data-id="prices-value-discount"
      category="Discount"
      sx={{
        paddingX: '2xs',
        letterSpacing: '-0.04em',
        whiteSpace: 'nowrap',
        '> span': {
          fontWeight: 'normal',
        },
      }}
    >
      {`${formattedPassengerPrice} ${t('pricing.belowPeak')}`}
    </BadgeValue>
  );
};

export const ValueOffSashV2: React.FC<ValueOffSashProps> = ({ pricing }) => {
  const { priceQuoteUnit } = usePriceQuoteUnit();

  return (
    <BadgeValue
      data-id="prices-value-discount"
      category="Discount"
      sx={{
        paddingX: '2xs',
        letterSpacing: '-0.04em',
        whiteSpace: 'nowrap',
        '> span': {
          fontWeight: 'normal',
        },
      }}
    >
      {priceQuoteUnit === 'PER_PERSON' && pricing.discountPresentation?.textPerPerson
        ? pricing.discountPresentation?.textPerPerson
        : pricing.discountPresentation?.textTotal}
    </BadgeValue>
  );
};
